import axios from "axios"
import { isBrowser } from "./util"



export const signUp = ({
    firstName,
    lastName,
    email,
    lists,
    industry,
    industrytime,
    birthday,
    userplus18yearsold,
    confirmation,
    occupation__pc,
    optSubject,
    optMessage,
    signupFormID,
    phone,
    employeeFocus,
    formIdentifier,
    agreeToPromotionalMessages,
    ...props }) => {
    return new Promise(function (resolve, reject) {
        if (!isBrowser()) {
            reject("Do not do this from Node!")
        } else {
            const dataToSubmit = {
                'email': email,
                'firstname': firstName,
                'lastname': lastName,
                'industry': industry || '',
                'industrytime': industrytime || '',
                'userplus18yearsold': userplus18yearsold || '',
                'opt_in_confirmation': confirmation || false,
                'opt_in_subject': optSubject || '',
                'opt_in_message': optMessage || '',
                'group_ids': lists,
                'signup_form_id': signupFormID || '',
                'birthday': birthday || '',
                'phone': phone || '',
                'employee_focus': employeeFocus || '',
                'form_identifier': formIdentifier || 'unknown',
                'occupation__pc': occupation__pc,
                'agree_to_promotional_messages': agreeToPromotionalMessages || false
            }

            axios.post(
                `https://jpanh4f3g2.execute-api.us-west-2.amazonaws.com/default/salesForceMailingListSubscribe`,
                dataToSubmit,
                {
                    headers: {
                        'X-Api-Key': 'Se4EmnT0Xj1Om3Nn5uqRI3WtR06b98ts1CywuHeE'
                    }
                }
            ).then((result) => {
                resolve(result)
            }).catch((err) => {
                reject(err)
            })
        }
    })
}